.page-list {
  display: flex;
  flex-wrap: wrap;
}

.page-icon {
  width: 3rem;
}

.page-separator {
  width: 1rem !important;
  margin-top: auto;
  margin-left: 1rem;
  margin-right: 1rem;
  height: 90% !important;
  background-color: black !important;
}

.darkmode-primary {
  background-color: #424242 !important;
  color: white !important;
}

.darkmode-secondary {
  background-color: #616161 !important;
  color: white !important;
}

.darkmode-tertiary {
  background-color: #969696 !important;
  color: white !important;
}

.col-container {
  display: table;
}

.col-item {
  display: table-cell;
}

.highlight-light {
  border: 4px solid white;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.highlight-dark {
  border: 4px solid #616161;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.highlight-red {
  border: 4px solid red;
  box-shadow: red 0px 54px 55px,
    red 0px -12px 30px, red 0px 4px 6px,
    red 0px 12px 13px, red 0px -3px 5px;
}

.selected-light {
  border: 4px solid white;
  box-shadow: red 0px 1px 4px, red 0px 0px 0px 3px;
  scale: 1.1;
}

.selected-dark {
  border: 4px solid #616161;
  box-shadow: red 0px 1px 4px, red 0px 0px 0px 3px;
  scale: 1.1;
}

.selected {
  border: 4px solid red;
  box-shadow: red 0px 1px 4px, red 0px 0px 0px 3px;
  scale: 1.1;
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}

.dropdown-toggle::after {
  display: none !important;
}

.striped-background {
  background-image: url("diagonal-background-safeimagekit.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.dotted-background {
  background-image: url("dots-blackgroung-safeimagekit.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.black-component {
  color: white !important;
  background-color: black !important;
  border-color: black !important;
}

.white-field {
  color: black !important;
  background-color: white !important;
}

.black-field {
  color: white !important;
  background-color: black !important;
}